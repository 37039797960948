import React from 'react';
import Card from '@last-rev/component-library/dist/components/Card';
import FadeIn from '../FadeIn';
export type { CardProps, CardClassKey, CardClasses } from '@last-rev/component-library/dist/components/Card';

const AnimatedCard = ({ ...props }: any) =>
  props.noAnimate ? (
    <Card {...props} />
  ) : (
    <FadeIn
      variants={{
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0, transition: { type: 'spring', damping: 50 } }
      }}
      style={{ width: '100%' }}
      threshold={0.3}
      delay={0.1}>
      <Card {...props} />
    </FadeIn>
  );
export default AnimatedCard;
